.main-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.main-wrapper .error-page-wrapper {
  width: 100%;
  height: 100%;
}
